import React from 'react';
import styled from 'styled-components';

export const Logo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 78 78"
    version="1.1"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 1.5,
    }}
  >
    <g transform="matrix(1,0,0,1,-5826,-575)">
      <g transform="matrix(1,0,0,1,4904.57,150.199)">
        <g transform="matrix(0.75,0,0,0.75,275.662,130.991)">
          <g transform="matrix(1.05154,0,0,1.05154,-528.598,-1302.73)">
            <rect
              x="1322.86"
              y="1613.13"
              width="95.098"
              height="95.098"
              style={{
                fill: 'none',
                strokeWidth: '2.54px',
              }}
            />
          </g>
          <g transform="matrix(1,0,0,1,-435.431,-1212.17)">
            <g transform="matrix(48,0,0,48,1315.51,1672.51)">
              <path
                d="M0.378,-0.7c0.091,0 0.162,0.022 0.214,0.065c0.052,0.043 0.078,0.103 0.078,0.179c0,0.076 -0.026,0.136 -0.078,0.179c-0.052,0.043 -0.123,0.065 -0.214,0.065l-0.173,0l0,0.212l-0.1,0l0,-0.7l0.273,0Zm-0.003,0.401c0.063,0 0.112,-0.013 0.145,-0.04c0.033,-0.027 0.05,-0.066 0.05,-0.117c0,-0.051 -0.017,-0.089 -0.05,-0.116c-0.033,-0.027 -0.082,-0.041 -0.145,-0.041l-0.17,0l0,0.314l0.17,0Z"
                style={{ stroke: 'none', fillRule: 'nonzero' }}
              />
            </g>
            <g transform="matrix(48,0,0,48,1346.85,1672.51)">
              <path
                d="M0.374,-0.535c0.067,0 0.121,0.02 0.16,0.058c0.04,0.04 0.06,0.097 0.06,0.172l0,0.305l-0.096,0l0,-0.294c0,-0.051 -0.012,-0.09 -0.037,-0.116c-0.025,-0.026 -0.06,-0.039 -0.106,-0.039c-0.052,0 -0.093,0.015 -0.123,0.045c-0.03,0.031 -0.045,0.074 -0.045,0.131l0,0.273l-0.096,0l0,-0.53l0.092,0l0,0.08c0.019,-0.027 0.045,-0.048 0.078,-0.063c0.033,-0.015 0.071,-0.022 0.113,-0.022Z"
                style={{ stroke: 'none', fillRule: 'nonzero' }}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const GitHub = () => (
  <StyledSVG width="100%" height="100%" viewBox="0 0 25 25" version="1.1">
    <path
      d="M12.68,0.611C6.05,0.611 0.68,5.984 0.68,12.611C0.68,17.914 4.118,22.411 8.885,23.996C9.485,24.109 9.705,23.738 9.705,23.419C9.705,23.134 9.695,22.379 9.69,21.379C6.352,22.103 5.648,19.769 5.648,19.769C5.102,18.384 4.313,18.014 4.313,18.014C3.226,17.27 4.397,17.285 4.397,17.285C5.602,17.369 6.235,18.521 6.235,18.521C7.305,20.356 9.044,19.826 9.73,19.519C9.838,18.743 10.147,18.214 10.49,17.914C7.825,17.614 5.024,16.582 5.024,11.984C5.024,10.674 5.489,9.604 6.259,8.764C6.124,8.461 5.719,7.241 6.364,5.588C6.364,5.588 7.369,5.266 9.664,6.818C10.624,6.551 11.644,6.419 12.664,6.413C13.684,6.419 14.704,6.551 15.664,6.818C17.944,5.266 18.949,5.588 18.949,5.588C19.594,7.241 19.189,8.461 19.069,8.764C19.834,9.604 20.299,10.674 20.299,11.984C20.299,16.594 17.494,17.609 14.824,17.904C15.244,18.264 15.634,19 15.634,20.124C15.634,21.73 15.619,23.02 15.619,23.41C15.619,23.725 15.829,24.1 16.444,23.98C21.245,22.406 24.68,17.906 24.68,12.611C24.68,5.984 19.307,0.611 12.68,0.611"
      style={{ fillRule: 'nonzero' }}
    />
  </StyledSVG>
);

export const LinkedIn = () => (
  <StyledSVG width="100%" height="100%" viewBox="0 0 25 25" version="1.1">
    <path
      d="M21.128,20.766L17.573,20.766L17.573,15.197C17.573,13.869 17.546,12.16 15.721,12.16C13.868,12.16 13.585,13.605 13.585,15.099L13.585,20.766L10.031,20.766L10.031,9.314L13.445,9.314L13.445,10.875L13.491,10.875C13.968,9.975 15.128,9.025 16.861,9.025C20.462,9.025 21.128,11.395 21.128,14.48L21.128,20.766ZM6.017,7.747C4.873,7.747 3.954,6.821 3.954,5.682C3.954,4.544 4.874,3.619 6.017,3.619C7.157,3.619 8.081,4.544 8.081,5.682C8.081,6.821 7.156,7.747 6.017,7.747ZM7.799,20.766L4.235,20.766L4.235,9.314L7.799,9.314L7.799,20.766ZM22.905,0.314L2.451,0.314C1.472,0.314 0.68,1.088 0.68,2.043L0.68,22.585C0.68,23.541 1.472,24.314 2.451,24.314L22.902,24.314C23.88,24.314 24.68,23.541 24.68,22.585L24.68,2.043C24.68,1.088 23.88,0.314 22.902,0.314L22.905,0.314Z"
      style={{ fillRule: 'nonzero' }}
    />
  </StyledSVG>
);

export const Twitter = () => (
  <StyledSVG width="100%" height="100%" viewBox="0 0 25 25" version="1.1">
    <clipPath id="_clip1">
      <rect x="0.68" y="0.103" width="24" height="24" />
    </clipPath>
    <g clipPath="url(#_clip1)">
      <path
        d="M24.634,4.672C23.749,5.061 22.804,5.326 21.809,5.447C22.823,4.836 23.603,3.873 23.972,2.724C23.021,3.279 21.967,3.683 20.845,3.908C19.949,2.949 18.672,2.349 17.254,2.349C14.537,2.349 12.334,4.552 12.334,7.266C12.334,7.656 12.379,8.031 12.461,8.39C8.371,8.197 4.746,6.233 2.32,3.264C1.893,3.986 1.654,4.825 1.654,5.739C1.654,7.449 2.524,8.952 3.842,9.835C3.035,9.809 2.276,9.587 1.614,9.219L1.614,9.28C1.614,11.665 3.307,13.654 5.56,14.107C5.147,14.218 4.711,14.278 4.264,14.278C3.95,14.278 3.649,14.248 3.348,14.192C3.979,16.145 5.793,17.569 7.952,17.609C6.272,18.928 4.143,19.714 1.85,19.714C1.46,19.714 1.071,19.691 0.68,19.647C2.869,21.041 5.448,21.856 8.237,21.856C17.291,21.856 22.236,14.36 22.236,7.87C22.236,7.661 22.236,7.45 22.221,7.24C23.182,6.551 24.021,5.68 24.681,4.692L24.634,4.672Z"
        style={{ fillRule: 'nonzero' }}
      />
    </g>
  </StyledSVG>
);

export const FaceBook = () => (
  <StyledSVG width="100%" height="100%" viewBox="0 0 24 24">
    <path d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z" />
  </StyledSVG>
);

export const EmailIcon = () => (
  <StyledSVG width="100%" height="100%" viewBox="0 0 136 111" version="1.1">
    <g id="EmailIcon">
      <path
        d="M130.881,14.667c0,-5.268 -4.277,-9.544 -9.545,-9.544l-105.911,0c-5.268,0 -9.544,4.276 -9.544,9.544l0,80.911c0,5.268 4.276,9.545 9.544,9.545l105.911,0c5.268,0 9.545,-4.277 9.545,-9.545l0,-80.911Z"
        style={{
          fill: 'none',
          strokeWidth: '10px',
        }}
      />
      <path
        d="M68.381,55.02l60.073,-46.812l-60.073,46.812Z"
        style={{
          fill: 'none',
          strokeWidth: '10px',
        }}
      />
      <path
        d="M8.401,8.208l59.98,46.915l-59.98,-46.915Z"
        style={{
          fill: 'none',
          strokeWidth: '10px',
        }}
      />
    </g>
  </StyledSVG>
);

export const HamburgerMenu = () => (
  <StyledSVG width="40" height="40" viewBox="0 0 100 65">
    <rect width="100" height="5" />
    <rect y="30" width="100" height="5" />
    <rect y="60" width="100" height="5" />
  </StyledSVG>
);

const StyledSVG = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
  fill: ${({ theme }) => theme.highlight};
  :hover {
    fill: ${({ theme }) => theme.brightBlue};
  }
`;
